import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "../../../Elements/Form/services/form"
import { formSchema } from "../utils/formSchema"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"

let { parseFormField } = require("../../../../services/airtable")

const EmployeeInformation = ({ pageContext }) => {
  // state and dispatch from flexmed cashless context
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )
  // form fields, validation, and parsing form fields to be sections
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  let medicines = flexmedCashlessState?.medicines
  let hasColdChainSKU = medicines.find((medicine) =>
    medicine?.skuTags?.includes?.("Cold chain")
  )

  const handleSubmitCashlessEmployeeInformation = (values) => {
    flexmedCashlessDispatch({
      type: "SAVE_EMPLOYEE_INFORMATION",
      payload: {
        ...values,
      },
    })
    flexmedCashlessDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...flexmedCashlessState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    flexmedCashlessDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout {...pageContext?.module} currentStep={4} totalSteps={4}>
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{ ...flexmedCashlessState }}
          onSubmit={handleSubmitCashlessEmployeeInformation}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      {section?.message}
                    </Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values: values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!!hasColdChainSKU ? (
                <Message color="warning">
                  We only deliver cold chain medicines within Metro Manila for
                  now. We will inform you of any positive changes. Thank you.
                </Message>
              ) : null}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ButtonGroup
                back={{
                  link: pageContext.backPath,
                }}
                next={{
                  label: `Next: ${pageContext.module.next}`,
                  link: pageContext.nextPath,
                  type: "submit",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default EmployeeInformation
